/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Navigate,
  useParams,
  useLocation,
  matchRoutes,
} from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Layout, Drawer, Spin } from "antd";
import { DndProvider } from "react-dnd";
import { useTranslation } from "react-i18next";
import { RootReducer } from "../../store/reducers/root-reducer";
import Navbar from "../navbar/navbar";
import Child from "../child-panel/child-panel";
import Parent from "../parent-panel/parent";
import {
  DataSourceId,
  LoggedInLayoutProp,
  StateInterface,
  StripeProduct,
} from "../../prop-types/prop-type";
import {
  Child as ChildType,
  Panel,
  TempChildType,
  TempParentType,
  TempPreparedChennelsType,
  TempPreparedDMType,
  TempSourceType,
} from "../../interfaces/user-detail-interface";
import "./logged-in-layout.scss";
import LoggedInSidebar from "../sidebar/logged-in-sidebar";
import MainFilter from "../filters/email-filter";
import SERVER_CONFIG from "../../utils/config";
import { updatePanel } from "../../store/reducers/panels/actions/panel-actions";
import useAxios from "../../services/axios";
import SocketConnection from "../../utils/socket/socket-connection";
import { setUserInfoAction } from "../../store/reducers/user/action/user-action";
import Todo from "../reusable-components/widgets/todo/todo";
import AllNotifications from "../notifications/all-natifications";
import HelpPage from "../help-page/help-page";
import { Participants } from "../participants/Participants";

interface ProfileData {
  data: {
    address: string;
    city: string;
    country: string;
    customerId: string;
    emailAddress: string;
    firstName: string;
    id: string;
    isActive: boolean;
    isParticipant: boolean;
    lastName: string;
    passWord: string;
    phoneNumber: string;
    primaryUserId: string;
    state: string;
    subscriptionId: string;
    subscriptionStatus: string;
    subscriptionType: string;
    username: string;
    zipCode: string;
  };
}

const { Header, Content } = Layout;

export const ActivePanelComp = () => {
  const { panelId, subpanelId }: LoggedInLayoutProp = useParams();
  const activePanels = useSelector(
    (state: RootReducer) => state.panels.activePanels
  );

  const currentPanel = activePanels.filter(
    (e: Panel | ChildType) => e.id === panelId
  );
  const root = document.documentElement;
  if (!subpanelId && !panelId && activePanels.length > 0) {
    return <Navigate to={`/panels/${activePanels[0].id}`} replace />;
  }
  // if (!currentPanel[0] && activePanels.length < 1) {
  //   return <Redirect to="/pathError" />;
  // }
  if (!currentPanel[0] && activePanels.length > 0) {
    return <Navigate to={`/panels/${activePanels[0].id}`} replace />;
  }
  if (!currentPanel[0] && activePanels[0]) {
    // eslint-disable-next-line prefer-destructuring
    currentPanel[0] = activePanels[0];
  }
  if (currentPanel[0] && currentPanel[0].color) {
    root.style.setProperty("--primary-theme-color", currentPanel[0].color);
  }
  if (subpanelId) {
    return <Child subpanelId={subpanelId} panelId={panelId} />;
  }
  if (!subpanelId && currentPanel[0] && currentPanel[0].isStandalone) {
    return <Child subpanelId="" panelId={panelId} />;
  }

  if (!panelId) {
    root.style.setProperty("--primary-theme-color", "#206FA8");
  }
  // one more case to show parent with zero children notification to add;
  return <Parent panelId={panelId} />;
};

export const ArchivedPanelComp = () => {
  const { panelId, subpanelId }: LoggedInLayoutProp = useParams();
  const archivedPanels = useSelector(
    (state: RootReducer) => state.panels.archivedPanels
  );

  const currentPanel = archivedPanels.filter(
    (e: Panel | ChildType) => e.id === panelId
  );
  const root = document.documentElement;
  if (!currentPanel[0] && archivedPanels.length < 1) {
    return <Navigate to="/pathError" replace />;
  }
  if (!currentPanel[0] && archivedPanels.length > 0) {
    return <Navigate to={`/apanels/${archivedPanels[0].id}`} replace />;
  }
  if (!currentPanel[0]) {
    // eslint-disable-next-line prefer-destructuring
    currentPanel[0] = archivedPanels[0];
  }
  if (currentPanel[0].color) {
    root.style.setProperty("--primary-theme-color", currentPanel[0].color);
  }
  if (subpanelId) {
    return <Child subpanelId={subpanelId} panelId={panelId} archived />;
  }
  if (!subpanelId && currentPanel[0].isStandalone) {
    return <Child subpanelId="" panelId={panelId} archived />;
  }
  // one more case to show parent with zero children notification to add;
  return <Parent panelId={panelId} archived />;
};

function LoggedInLayout() {
  const dispatch = useDispatch<any>();
  const [visibleAddWidget, setVisibleAddWidget] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [visible, setVisible] = useState(false);
  const params = useParams();
  const { panels, panelId, subpanelId } = params;
  const routes = [
    { path: "/panels/*" },
    { path: "/apanels/*" },
    { path: "/filters/*" },
    { path: "/notifications/*" },
    { path: "/help/*" },
    { path: "/participants/*" },
  ];
  const loc = useLocation();
  const useCurrentPath = () => {
    const location = useLocation();
    // eslint-disable-next-line
    // @ts-ignore
    const [{ route }] = matchRoutes(routes, location);
    return route.path;
  };
  const currPath = useCurrentPath();
  // const val =  state.panels | panels;
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const { t } = useTranslation();
  const [panelsResponse, panelsLoading, error, axiosFetch] = useAxios();

  const [
    userDetails,
    userDetailsloading,
    userDetailsError,
    fetchLoggedUserInfo,
  ] = useAxios();
  const { activePanels, archivedPanels } = useSelector(
    (state: RootReducer) => state.panels
  );
  const todo: StateInterface = useSelector((state: RootReducer) => state.todo);

  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = `(function (w, d, s) {
      var a = d.getElementsByTagName('head')[0];
      var r = d.createElement('script');
      r.async = 1;
      r.src = s;
      r.setAttribute('id', 'usetifulScript');
      r.dataset.token = "a419c3f5411a5b888ce63549ee699c3c";
                          a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");`;

    document.body.appendChild(s);
  }, []);

  // start socket connection
  useEffect(() => {
    fetchLoggedUserInfo({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: SERVER_CONFIG.API_URL.COMMON_APIS.USER_INFO,
    });
  }, []);

  useEffect(() => {
    const res: ProfileData = userDetails?.data;
    if (res) {
      const { isParticipant, subscriptionType } = res.data;

      const userSubscription =
        subscriptionType === StripeProduct.Standard
          ? StripeProduct.Standard
          : StripeProduct.Premium;

      dispatch(
        setUserInfoAction({
          ...res.data,
          subscription: isParticipant
            ? {
                type: StripeProduct.Participant,
                expiration: "",
              }
            : {
                type: userSubscription,
                expiration: "",
              },
        })
      );
    }
  }, [userDetails]);
  SocketConnection();
  useEffect(() => {
    if (!activePanels.length) {
      // eslint-disable-next-line
      // @ts-ignore
      axiosFetch({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.PANELS_GET,
      });
    }
  }, []);

  useEffect(() => {
    if (panelsResponse) {
      const { data } = panelsResponse;
      if (data && data.success) {
        const tempPanels = [...data.panels.activePanels];
        tempPanels.forEach((item: TempParentType, itemIndex) => {
          if (!item.isStandalone) {
            const tempChildren = [...item.children];
            tempChildren.forEach((child: TempChildType, childIndex) => {
              const tempSources: TempSourceType[] = [...child.sources];
              tempSources.forEach((source: TempSourceType, sourceIndex) => {
                if (source.sourceId === DataSourceId.SLACK) {
                  const { slackData } = source;
                  const { slackId } = slackData;
                  const { data: dataObj } = slackId;
                  const slack = {
                    [slackData.email]: {
                      [slackId.workSpaceId]: {
                        slackName: dataObj.slackName,
                        channels: {} as TempPreparedChennelsType,
                        directMessages: {} as TempPreparedDMType,
                      },
                    },
                  };
                  dataObj.channels.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].channels[
                      e.channelId
                    ] = { channelName: e.channelName };
                  });
                  dataObj.directMessage.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].directMessages[
                      e.channelId
                    ] = {
                      directMessagesName: e.directMessageName,
                      channelId: e.channelId,
                    };
                  });
                  const updatedSourceDetails = { ...source, data: { slack } };
                  tempPanels[itemIndex].children[childIndex].sources.splice(
                    sourceIndex,
                    1,
                    updatedSourceDetails
                  );
                }
              });
            });
          } else {
            const tempSources = [...item.sources];
            tempSources.forEach((source: TempSourceType, sourceIndex) => {
              if (source.sourceId === DataSourceId.SLACK) {
                const { slackData } = source;
                const { slackId } = slackData;
                const { data: dataObj } = slackId;
                const slack = {
                  [slackData.email]: {
                    [slackId.workSpaceId]: {
                      slackName: dataObj.slackName,
                      channels: {} as TempPreparedChennelsType,
                      directMessages: {} as TempPreparedDMType,
                    },
                  },
                };
                dataObj.channels.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].channels[
                    e.channelId
                  ] = { channelName: e.channelName };
                });
                dataObj.directMessage.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].directMessages[
                    e.channelId
                  ] = {
                    directMessagesName: e.directMessageName,
                    channelId: e.channelId,
                  };
                });
                const updatedSourceDetails = { ...source, data: { slack } };
                tempPanels[itemIndex].sources.splice(
                  sourceIndex,
                  1,
                  updatedSourceDetails
                );
              }
            });
          }
        });
        const tempArchivePanels = [...data.panels.archivedPanels];
        tempArchivePanels.forEach((item: TempParentType, itemIndex) => {
          if (!item.isStandalone) {
            const tempChildren = [...item.children];
            tempChildren.forEach((child: TempChildType, childIndex) => {
              const tempSources: TempSourceType[] = [...child.sources];
              tempSources.forEach((source: TempSourceType, sourceIndex) => {
                if (source.sourceId === DataSourceId.SLACK) {
                  const { slackData } = source;
                  const { slackId } = slackData;
                  const { data: dataObj } = slackId;
                  const slack = {
                    [slackData.email]: {
                      [slackId.workSpaceId]: {
                        slackName: dataObj.slackName,
                        channels: {} as TempPreparedChennelsType,
                        directMessages: {} as TempPreparedDMType,
                      },
                    },
                  };
                  dataObj.channels.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].channels[
                      e.channelId
                    ] = { channelName: e.channelName };
                  });
                  dataObj.directMessage.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].directMessages[
                      e.channelId
                    ] = {
                      directMessagesName: e.directMessageName,
                      channelId: e.channelId,
                    };
                  });
                  const updatedSourceDetails = { ...source, data: { slack } };
                  tempArchivePanels[itemIndex].children[
                    childIndex
                  ].sources.splice(sourceIndex, 1, updatedSourceDetails);
                }
              });
            });
          } else {
            const tempSources = [...item.sources];
            tempSources.forEach((source: TempSourceType, sourceIndex) => {
              if (source.sourceId === DataSourceId.SLACK) {
                const { slackData } = source;
                const { slackId } = slackData;
                const { data: dataObj } = slackId;
                const slack = {
                  [slackData.email]: {
                    [slackId.workSpaceId]: {
                      slackName: dataObj.slackName,
                      channels: {} as TempPreparedChennelsType,
                      directMessages: {} as TempPreparedDMType,
                    },
                  },
                };
                dataObj.channels.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].channels[
                    e.channelId
                  ] = { channelName: e.channelName };
                });
                dataObj.directMessage.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].directMessages[
                    e.channelId
                  ] = {
                    directMessagesName: e.directMessageName,
                    channelId: e.channelId,
                  };
                });
                const updatedSourceDetails = { ...source, data: { slack } };
                tempArchivePanels[itemIndex].sources.splice(
                  sourceIndex,
                  1,
                  updatedSourceDetails
                );
              }
            });
          }
        });
        dispatch(updatePanel(tempPanels, tempArchivePanels));
      }
    }
  }, [panelsResponse]);

  const isParams = !!panelId || !!subpanelId;

  return (
    <Spin size="large" spinning={panelsLoading as boolean}>
      <DndProvider backend={HTML5Backend}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "100vh",
            width: "100%",
            background: "#f2f2f2",
          }}
        >
          {isMenuOpen && (
            <div className="destop-sidebar">
              <LoggedInSidebar panels={currPath} />
            </div>
          )}
          <Drawer placement="left" onClose={onClose} visible={visible}>
            <LoggedInSidebar panels={currPath} />
          </Drawer>
          <Layout className="site-layout">
            <Header style={{ padding: 0, lineHeight: 0, height: "30px" }}>
              <Navbar
                setVisibleAddWidget={setVisibleAddWidget}
                setOpen={setIsMenuOpen}
                open={isMenuOpen}
                showDrawer={showDrawer}
                visible={visible}
              />
            </Header>
            <Content id="contentLayout" style={{ background: "#f2f2f2" }}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection:
                    todo?.todoboard_show || currPath.includes("/apanels/")
                      ? "column"
                      : "row",
                }}
              >
                {visibleAddWidget ? (
                  <Todo todoType={todo?.todoboard_show} />
                ) : null}
                {isParams
                  ? currPath.includes("/panels/") &&
                    activePanels.length > 0 && <ActivePanelComp />
                  : currPath.includes("/panels/") && <ActivePanelComp />}
                {isParams
                  ? currPath.includes("/apanels/") &&
                    archivedPanels.length > 0 && <ArchivedPanelComp />
                  : currPath.includes("/apanels/") && <ArchivedPanelComp />}
                {currPath.includes("filters") && activePanels.length > 0 && (
                  <MainFilter panelId={panelId} subpanelId={subpanelId} />
                )}
                {currPath.includes("notifications") && <AllNotifications />}
                {currPath.includes("help") && <HelpPage />}
                {currPath.includes("participants") && <Participants />}
              </div>
            </Content>
          </Layout>
        </div>
      </DndProvider>
    </Spin>
  );
}

export default LoggedInLayout;
